import React from 'react';
import PropTypes from 'prop-types';
import { Pane, Text, Dialog, TextInput, Label } from 'evergreen-ui';

function TemplateModal({
    openModal,
    handleCloseDialog,
    type,
    editing,
    deleting,
    modalLoading,
    selectedSubject,
    handleConfirm,
    handleChange,
    form,
}) {
    const getTitle = () => {
        if (deleting) {
            return `Delete ${type === 'category' ? 'Category' : 'Type'}`;
        }
        if (editing) {
            return `Edit ${type === 'category' ? 'Category' : 'Type'}`;
        }
        return `Add ${type === 'category' ? 'Category' : 'Type'}`;
    };

    const getConfirmLabel = () => {
        if (deleting) {
            return 'Delete';
        }
        if (editing) {
            return `Update ${type === 'category' ? 'Category' : 'Type'}`;
        }
        return `Add ${type === 'category' ? 'Category' : 'Type'}`;
    };

    const renderContent = () => {
        if (deleting) {
            return (
                <Text>
                    Are you sure you want to delete this{' '}
                    {type === 'category' ? 'Category' : 'Type'}?
                </Text>
            );
        }
        if (selectedSubject === 'maintenance') {
            if (type === 'category') {
                return (
                    <>
                        <Pane marginBottom={16}>
                            <Label
                                htmlFor="category-name"
                                marginBottom={4}
                                display="block"
                            >
                                Name
                            </Label>
                            <TextInput
                                id="category-name"
                                required
                                name="name"
                                label="Name"
                                placeholder="Mechanical"
                                value={form.values.category?.name}
                                validationMessage={
                                    !!form.errors.category?.name &&
                                    form.errors.category?.name
                                }
                                onChange={handleChange}
                                width="100%"
                            />
                        </Pane>

                        <Pane marginBottom={16}>
                            <Label
                                htmlFor="category-abbreviation"
                                marginBottom={4}
                                display="block"
                            >
                                Abbreviation
                            </Label>
                            <TextInput
                                id="category-abbreviation"
                                required
                                name="abbreviation"
                                label="Abbreviation"
                                placeholder="MC"
                                value={form.values.category?.abbreviation}
                                validationMessage={
                                    !!form.errors.category?.abbreviation &&
                                    form.errors.category?.abbreviation
                                }
                                onChange={handleChange}
                                width="100%"
                            />
                        </Pane>
                    </>
                );
            }
        }

        if (selectedSubject === 'tasks') {
            if (type === 'category') {
                return (
                    <Pane marginBottom={16}>
                        <Label
                            htmlFor="category-name"
                            marginBottom={4}
                            display="block"
                        >
                            Name
                        </Label>
                        <TextInput
                            id="category-name"
                            name="name"
                            label="Name"
                            placeholder="Mechanical"
                            value={form.values.category?.name}
                            validationMessage={
                                !!form.errors.category?.name &&
                                form.errors.category?.name
                            }
                            onChange={handleChange}
                            width="100%"
                        />
                    </Pane>
                );
            }
        }

        return (
            <Pane marginBottom={16}>
                <Label htmlFor="type-name" marginBottom={4} display="block">
                    Name
                </Label>
                <TextInput
                    id="type-name"
                    required
                    name="name"
                    label="Name"
                    placeholder="Generators"
                    value={form.values.type?.name}
                    validationMessage={
                        !!form.errors.type?.name && form.errors.type?.name
                    }
                    onChange={(e) => handleChange(e, false)}
                    width="100%"
                />
            </Pane>
        );
    };

    return (
        <Dialog
            isShown={openModal}
            onConfirm={handleConfirm}
            title={getTitle()}
            confirmLabel={getConfirmLabel()}
            onCancel={handleCloseDialog}
            intent={deleting ? 'danger' : 'none'}
            isConfirmLoading={modalLoading}
            onCloseComplete={handleCloseDialog}
        >
            <Pane>{renderContent()}</Pane>
        </Dialog>
    );
}

TemplateModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['category', 'type']).isRequired,
    editing: PropTypes.bool,
    deleting: PropTypes.bool,
    modalLoading: PropTypes.bool,
    selectedSubject: PropTypes.oneOf(['maintenance', 'task']).isRequired,
    handleConfirm: PropTypes.string.isRequired,
    handleChange: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
};

TemplateModal.defaultProps = {
    editing: false,
    deleting: false,
    modalLoading: false,
};

export default TemplateModal;
