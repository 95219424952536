import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    Pane,
    Tablist,
    Tab,
    Card,
    Heading,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../../theme/theme';
import { maintenanceSelector } from '../../reducers/maintenance-reducer/maintenance-reducer';
import {
    getMaintenanceCategoryModel,
    getMaintenanceTypeModel,
} from '../../models/maintenance.models';
import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import { getTaskCategoryModel } from '../../models/task.models';
import PageLoading from '../../components/molecules/page-loading';
import MaintenanceTemplateForm from '../../components/template-management/MaintenanceTemplateForm';
import TaskTemplageForm from '../../components/template-management/TaskTemplageForm';
import TemplateModal from '../../components/template-management/TemplateModal';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';

import {
    createMaintenanceCategory,
    createMaintenanceType,
    deleteMaintenanceCategory,
    deleteMaintenanceType,
    updateMaintenanceCategory,
    updateMaintenanceType,
    getMaintenanceCategories,
} from '../../reducers/maintenance-reducer/maintenance-actions';
import { errorToast, successToast } from '../../components/toasts';

import {
    createTasksCategory,
    createTasksType,
    deleteTasksCategory,
    deleteTasksType,
    updateTasksCategory,
    updateTasksType,
    getTasksCategories,
} from '../../reducers/task-reducer/task.actions';

const SUBJECTS = ['maintenance', 'tasks'];

function TemplateManagement() {
    const [loading, setLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState('maintenance');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [editingModal, setEditingModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [categoryConfirmLoading, setCategoryConfirmLoading] = useState(false);
    const [modalData, setModalData] = useState({
        name: '',
        abbreviation: '',
    });
    const [form, setForm] = useState({
        values: {
            category: {
                name: selectedCategory?.name || '',
                abbreviation: selectedCategory?.abbreviation || '',
            },
            type: {
                name: selectedType?.name || '',
                categoryId: selectedCategory?.id || '',
            },
        },
        errors: {
            category: {
                name: '',
                abbreviation: '',
            },
            type: {
                name: '',
            },
        },
    });
    const [types, setTypes] = useState([]);

    const dispatch = useDispatch();

    const { currentRights } = useUserAccess();

    const { create } = useMemo(
        () => currentRights || { create: false },
        [currentRights]
    );

    const { categories: maintenanceCategories } =
        useSelector(maintenanceSelector);
    const { categories: taskCategories } = useSelector(taskSelector);

    const categories = useMemo(() => {
        if (selectedSubject === 'maintenance') {
            return maintenanceCategories.map((category) =>
                getMaintenanceCategoryModel(category)
            );
        }
        return taskCategories.map((category) => getTaskCategoryModel(category));
    }, [maintenanceCategories, selectedSubject, taskCategories]);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            await Promise.all([
                dispatch(getTasksCategories()),
                dispatch(getMaintenanceCategories()),
                dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const isInvalid = (isCategory = true) => {
        const { values } = form;
        if (isCategory) {
            if (!values.category.name) {
                setForm((state) => ({
                    ...state,
                    errors: {
                        category: {
                            name: 'Category Name is Required',
                        },
                    },
                }));
                return true;
            }
            if (selectedSubject === 'maintenance') {
                if (!values.category.abbreviation) {
                    setForm((state) => ({
                        ...state,
                        errors: {
                            ...state.errors,
                            category: {
                                abbreviation:
                                    'Category Abbreviation is Required',
                            },
                        },
                    }));
                    return true;
                }
            }
        }
        if (!isCategory) {
            if (!values.type.name) {
                setForm((state) => ({
                    ...state,
                    errors: {
                        ...state.errors,
                        type: {
                            name: 'Type Name is Required',
                        },
                    },
                }));
                return true;
            }
        }
        return false;
    };

    const handleConfirm = async () => {
        try {
            if (selectedSubject === 'maintenance') {
                if (modalType === 'category') {
                    if (deleteModal) {
                        await deleteMaintnenanceCategory();
                    } else {
                        await submitMaintnenanceCategory();
                    }
                } else if (modalType === 'type') {
                    if (deleteModal) {
                        await deleteMaintnenanceType();
                    } else {
                        await submitMaintnenanceType();
                    }
                }
            } else if (selectedSubject === 'tasks') {
                if (modalType === 'category') {
                    if (deleteModal) {
                        await deleteTaskCategory();
                    } else {
                        await submitTaskCategory();
                    }
                } else if (modalType === 'type') {
                    if (deleteModal) {
                        await deleteTaskType();
                    } else {
                        await submitTaskType();
                    }
                }
            } else {
                throw new Error('Invalid subject or modal type provided');
            }
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            handleCloseDialog();
        }
    };

    const submitMaintnenanceCategory = async () => {
        setCategoryConfirmLoading(true);
        try {
            if (isInvalid()) {
                return;
            }

            const category = await dispatch(
                selectedCategory
                    ? updateMaintenanceCategory(
                          selectedCategory.id,
                          form.values.category.name,
                          form.values.category.abbreviation
                      )
                    : createMaintenanceCategory(
                          form.values.category.name,
                          form.values.category.abbreviation,
                          false,
                          true
                      )
            );
            successToast(
                `Successfully ${
                    selectedCategory ? 'updated' : 'created'
                } category "${selectedCategory?.name || category?.name}"`
            );
            setSelectedCategory(category);
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    category: {
                        abbreviation: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setCategoryConfirmLoading(false);
        }
    };

    const deleteMaintnenanceCategory = async () => {
        setModalLoading(true);
        try {
            await dispatch(deleteMaintenanceCategory(selectedCategory.id));
            setSelectedCategory(null);
            setTypes([]);
            successToast(`Successfully deleted Category`);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setModalLoading(false);
        }
    };

    const submitMaintnenanceType = async () => {
        setModalLoading(true);
        try {
            if (isInvalid(false)) {
                return;
            }
            const type = getMaintenanceTypeModel(
                await dispatch(
                    selectedType
                        ? updateMaintenanceType(
                              selectedType.id,
                              form.values.type.name
                          )
                        : createMaintenanceType(
                              selectedCategories.id,
                              form.values.type.name
                          )
                )
            );
            setForm((_form) => ({
                ..._form,
                values: {
                    ..._form.values,
                    type: {
                        name: '',
                    },
                },
            }));
            if (!selectedType) {
                setTypes((_types) => [..._types, type]);
            } else {
                setTypes(
                    types.map((_type) => {
                        if (Number(_type.id) === Number(type.id)) {
                            return type;
                        }
                        return _type;
                    })
                );
            }
            setSelectedType(null);
            successToast(
                `Successfully ${selectedType ? 'updated' : 'created'} type "${
                    selectedType?.name || type.name
                }"`
            );
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    type: {
                        name: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setModalLoading(false);
        }
    };

    const deleteMaintnenanceType = async () => {
        setModalLoading(true);
        try {
            dispatch(deleteMaintenanceType(selectedType.id));
            setTypes((state) =>
                state.filter(
                    (type) => Number(type.id) !== Number(selectedType.id)
                )
            );
            setSelectedType(null);
            successToast(`Successfully deleted type`);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setModalLoading(false);
        }
    };

    const submitTaskCategory = async () => {
        setCategoryConfirmLoading(true);
        try {
            if (isInvalid()) {
                return;
            }

            const category = getTaskCategoryModel(
                await dispatch(
                    selectedCategory
                        ? updateTasksCategory({
                              categoryId: selectedCategory?.id,
                              name: form.values.category.name,
                          })
                        : createTasksCategory(form.values.category.name)
                )
            );
            successToast(
                `Successfully ${
                    selectedCategory ? 'updated' : 'created'
                } category "${selectedCategory?.name || category?.name}"`
            );
            setSelectedCategory(category);
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    category: {
                        name: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setCategoryConfirmLoading(false);
        }
    };

    const deleteTaskCategory = async () => {
        setModalLoading(true);
        try {
            await dispatch(deleteTasksCategory(selectedCategory.id));
            setSelectedCategory(null);
            setTypes([]);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setModalLoading(false);
        }
    };

    const submitTaskType = async () => {
        setModalLoading(true);
        try {
            if (isInvalid(false)) {
                return;
            }
            const type = await dispatch(
                selectedType
                    ? updateTasksType({
                          typeId: selectedType.id,
                          value: form.values.type.name,
                      })
                    : createTasksType({
                          categoryId: selectedCategories.id,
                          newTypeName: form.values.type.name,
                      })
            );
            setForm((_form) => ({
                ..._form,
                values: {
                    ..._form.values,
                    type: {
                        name: '',
                    },
                },
            }));
            if (!selectedType) {
                setTypes((_types) => [..._types, type]);
            } else {
                setTypes(
                    types.map((_type) => {
                        if (Number(_type.id) === Number(type.id)) {
                            return type;
                        }
                        return _type;
                    })
                );
            }
            setSelectedType(null);
            successToast(
                `Successfully ${selectedType ? 'updated' : 'created'} type "${
                    selectedType?.name || type?.name
                }"`
            );
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    type: {
                        name: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setModalLoading(false);
        }
    };

    const deleteTaskType = async () => {
        setModalLoading(true);
        try {
            dispatch(deleteTasksType(selectedType.id));
            setTypes((state) =>
                state.filter(
                    (type) => Number(type.id) !== Number(selectedType.id)
                )
            );
            setSelectedType(null);
            successToast(`Successfully deleted type`);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setModalLoading(false);
        }
    };

    const handleChange = ({ target }, isCategory = true) => {
        if (isCategory) {
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    category: {
                        ...state.values.category,
                        [target.name]: target.value,
                    },
                },
                errors: {
                    ...state.errors,
                    category: {
                        ...state.errors.category,
                        [target.name]: '',
                    },
                },
            }));
        } else {
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    type: {
                        ...state.values.type,
                        [target.name]: target.value,
                    },
                },
                errors: {
                    type: {
                        [target.name]: '',
                    },
                },
            }));
        }
    };

    const handleSubjectChange = async (subject) => {
        setSelectedSubject(subject);
        setSelectedCategory(null);
        setSelectedType(null);
        setTypes([]);
        await getData();
    };

    const handleCategoryChange = (category) => {
        if (Number(category.id) === Number(selectedCategory?.id) && create) {
            setSelectedCategory(null);
            setTypes([]);
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    category: {
                        name: '',
                        abbreviation: '',
                    },
                    type: {
                        name: '',
                        categoryId: '',
                    },
                },
                errors: {
                    ...state.errors,
                    category: {
                        name: '',
                        abbreviation: '',
                    },
                    type: {
                        name: '',
                    },
                },
            }));
            return;
        }
        setTypes(category.types);
        setSelectedCategory(category);
        setForm((state) => ({
            ...state,
            values: {
                ...state.values,
                category: {
                    name: category.name || '',
                    abbreviation: category.abbreviation || '',
                },
            },
            errors: {
                ...state.errors,
                category: {
                    name: '',
                    abbreviation: '',
                },
                type: {
                    name: '',
                },
            },
        }));
        if (create) {
            setSelectedType(null);
        } else {
            setSelectedType(category.types[0]);
        }
    };

    const handleTypeChange = (type) => {
        if (Number(type.id) === Number(selectedType?.id) && create) {
            setSelectedType(null);
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    type: {
                        name: '',
                        categoryId: '',
                    },
                },
                errors: {
                    ...state.errors,
                    type: {
                        name: '',
                    },
                },
            }));
            return;
        }
        setSelectedType(type);
        setForm((state) => ({
            ...state,
            values: {
                ...state.values,
                type: {
                    name: type.name || '',
                    categoryId: type.categoryId || '',
                },
            },
            errors: {
                ...state.errors,
                type: {
                    name: '',
                },
            },
        }));
    };

    useEffect(() => {
        if (!create) {
            setSelectedCategory(categories[0]);
            setTypes(categories[0]?.types);
            setSelectedType(categories[0]?.types[0]);
        }
    }, [categories, create]);

    useEffect(() => {
        getData();
    }, [getData]);

    const handleOpenDialog = () => {
        setOpenModal(true);
    };

    const handleCloseDialog = () => {
        setOpenModal(false);
        setModalType(null);
        setEditingModal(false);
        setSelectedCategory(null);
        setDeleteModal(false);
        setModalData({
            name: '',
            abbreviation: '',
        });
    };

    return (
        <Pane
            display="flex"
            width="95%"
            maxWidth={1440}
            marginX="auto"
            paddingTop={20}
            height="calc(100vh - 145px)"
        >
            <Pane
                width="100%"
                display="flex"
                flexDirection="column"
                position="sticky"
                top={0}
                zIndex={1}
                gap={12}
                backgroundColor="#f2f4fa"
            >
                <Pane display="flex" alignItems="center" gap={10}>
                    <Heading size={700} fontSize={24} paddingBottom={10}>
                        Template Management
                    </Heading>
                    <Tooltip content="Efficiently customize your dropdown categories and subcategories/types here">
                        <InfoSignIcon marginTop={-10} />
                    </Tooltip>
                </Pane>
                <Pane
                    display="flex"
                    width="100%"
                    background="white"
                    borderRadius={8}
                    overflow="hidden"
                >
                    <Tablist
                        display="flex"
                        alignItems="end"
                        height={35}
                        background="white"
                        width="100%"
                    >
                        {SUBJECTS.map((subject) => {
                            const isSelected = subject === selectedSubject;
                            return (
                                <StyledTab
                                    key={subject}
                                    borderRadius={0}
                                    onSelect={() =>
                                        handleSubjectChange(subject)
                                    }
                                    isSelected={isSelected}
                                    backgroundColor={colors.white}
                                    borderBottom={
                                        isSelected
                                            ? '3px solid #A3E6CD'
                                            : 'none'
                                    }
                                    fontWeight={isSelected ? '700' : '500'}
                                    fontSize={16}
                                    color="#0C2138"
                                >
                                    {subject}
                                </StyledTab>
                            );
                        })}
                    </Tablist>
                </Pane>
                <Card
                    display="flex"
                    width="100%"
                    height="calc(100% - 130px)"
                    background="white"
                    overflow="hidden"
                    borderRadius={8}
                >
                    {loading ? (
                        <PageLoading />
                    ) : (
                        <Pane display="flex" width="100%">
                            {selectedSubject === 'maintenance' && (
                                <MaintenanceTemplateForm
                                    handleOpenDialog={handleOpenDialog}
                                    setModalType={setModalType}
                                    setEditingModal={setEditingModal}
                                    setDeleteModal={setDeleteModal}
                                    setModalData={setModalData}
                                    categories={categories}
                                    handleCategoryChange={handleCategoryChange}
                                    handleTypeChange={handleTypeChange}
                                    selectedCategory={selectedCategory}
                                    selectedType={selectedType}
                                    setSelectedCategory={setSelectedCategory}
                                    setSelectedType={setSelectedType}
                                    types={types}
                                    categoryConfirmLoading={
                                        categoryConfirmLoading
                                    }
                                    setForm={setForm}
                                    setSelectedCategories={
                                        setSelectedCategories
                                    }
                                />
                            )}
                            {selectedSubject === 'tasks' && (
                                <TaskTemplageForm
                                    handleOpenDialog={handleOpenDialog}
                                    setModalType={setModalType}
                                    setEditingModal={setEditingModal}
                                    setDeleteModal={setDeleteModal}
                                    setModalData={setModalData}
                                    categories={categories}
                                    handleCategoryChange={handleCategoryChange}
                                    handleTypeChange={handleTypeChange}
                                    selectedCategory={selectedCategory}
                                    selectedType={selectedType}
                                    setSelectedCategory={setSelectedCategory}
                                    setSelectedType={setSelectedType}
                                    types={types}
                                    categoryConfirmLoading={
                                        categoryConfirmLoading
                                    }
                                    setForm={setForm}
                                    setSelectedCategories={
                                        setSelectedCategories
                                    }
                                />
                            )}
                            <TemplateModal
                                openModal={openModal}
                                handleCloseDialog={handleCloseDialog}
                                type={modalType}
                                editing={editingModal}
                                deleting={deleteModal}
                                data={modalData}
                                modalLoading={modalLoading}
                                selectedSubject={selectedSubject}
                                handleConfirm={handleConfirm}
                                handleChange={handleChange}
                                form={form}
                            />
                        </Pane>
                    )}
                </Card>
            </Pane>
        </Pane>
    );
}

const StyledTab = styled(Tab)`
    &[aria-selected='true'] {
        background-color: #ffffff;
    }

    &:hover {
        background-color: #ffffff;
    }

    &:focus {
        box-shadow: none;
    }
    text-transform: capitalize;
`;

export default TemplateManagement;
