import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Text,
    Pane,
    Button,
    PlusIcon,
    EditIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from 'evergreen-ui';
import { colors } from '../../theme/theme';

function MaintenanceTemplateForm({
    handleOpenDialog,
    setModalType,
    setEditingModal,
    setDeleteModal,
    setModalData,
    categories,
    handleCategoryChange,
    handleTypeChange,
    categoryConfirmLoading,
    setSelectedCategory,
    setSelectedType,
    setForm,
    setSelectedCategories,
}) {
    const [expandedIndexes, setExpandedIndexes] = useState(new Set());

    const handleToggle = (index) => {
        setExpandedIndexes((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };

    return (
        <Pane padding="30px" width="100%" overflow="auto">
            {categories.map((category, index) => (
                <Pane border borderRadius={8} width="100%" marginBottom={8}>
                    <Pane
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Item
                            isExpanded={expandedIndexes.has(index)}
                            onClick={() => {
                                handleCategoryChange(category);
                            }}
                        >
                            {category.abbreviation} {category.name}
                        </Item>

                        <ItemPane
                            onClick={(e) => e.stopPropagation()}
                            isExpanded={expandedIndexes.has(index)}
                        >
                            <Pane
                                marginRight="16px"
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCategoryChange(category);
                                    handleOpenDialog();
                                    setModalType('category');
                                    setEditingModal(true);
                                    setModalData(category);
                                }}
                            >
                                <EditIcon
                                    size={16}
                                    color="#5EC090"
                                    className="edit-category"
                                />{' '}
                            </Pane>
                            <Pane
                                cursor="pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCategoryChange(category);
                                    handleOpenDialog();
                                    setModalType('category');
                                    setDeleteModal(true);
                                }}
                            >
                                <TrashIcon
                                    size={16}
                                    color="#c60b0b"
                                    className="delete-category"
                                />{' '}
                            </Pane>
                            <Pane
                                cursor="pointer"
                                marginLeft="25px"
                                paddingRight="16px"
                                onClick={() => {
                                    handleToggle(index);
                                }}
                            >
                                {expandedIndexes.has(index) ? (
                                    <ChevronDownIcon size={15} />
                                ) : (
                                    <ChevronRightIcon size={15} />
                                )}
                            </Pane>
                        </ItemPane>
                    </Pane>
                    {expandedIndexes.has(index) && (
                        <>
                            <Pane
                                key={category.id}
                                paddingTop="8px"
                                width="100%"
                                marginRight="32px"
                                marginLeft="16px"
                                paddingRight="32px"
                                paddingBottom="16px"
                            >
                                {category.types.map((type) => (
                                    <Pane
                                        key={`${type.id}-${type.name}`}
                                        border
                                        borderRadius={8}
                                        padding="16px"
                                        width="100%"
                                        marginTop="8px"
                                    >
                                        <Pane
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Text width="70%">{type.name}</Text>
                                            <Pane
                                                display="flex"
                                                marginRight="16px"
                                                justifyContent="flex-end"
                                                width="30%"
                                            >
                                                <Pane
                                                    marginRight="16px"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setSelectedCategory(
                                                            null
                                                        );
                                                        handleTypeChange(type);
                                                        handleOpenDialog();
                                                        setModalType('type');
                                                        setEditingModal(true);
                                                        setModalData(type);
                                                    }}
                                                >
                                                    <EditIcon
                                                        size={16}
                                                        color="#5EC090"
                                                        className="edit-category"
                                                    />{' '}
                                                </Pane>
                                                <Pane
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        handleTypeChange(type);
                                                        handleOpenDialog();
                                                        setModalType('type');
                                                        setDeleteModal(true);
                                                    }}
                                                >
                                                    <TrashIcon
                                                        size={16}
                                                        color="#c60b0b"
                                                        className="delete-category"
                                                    />{' '}
                                                </Pane>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                ))}
                            </Pane>
                            <Pane
                                display="flex"
                                alignItems="center"
                                minWidth={125}
                                paddingBottom="16px"
                                paddingLeft="32px"
                                backgroundColor="transparent"
                                borderRadius={8}
                                cursor="pointer"
                                onClick={() => {
                                    handleOpenDialog();
                                    setModalType('type');
                                    setSelectedCategories(category);
                                    setForm((state) => ({
                                        ...state,
                                        values: {
                                            ...state.values,
                                            category: {
                                                name: '',
                                                abbreviation: '',
                                            },
                                            type: {
                                                name: '',
                                                categoryId: '',
                                            },
                                        },
                                        errors: {
                                            ...state.errors,
                                            category: {
                                                name: '',
                                                abbreviation: '',
                                            },
                                            type: {
                                                name: '',
                                            },
                                        },
                                    }));
                                }}
                                style={{
                                    color: '#5EC090',
                                }}
                            >
                                <PlusIcon
                                    size={11}
                                    color="#5EC090"
                                    style={{ marginRight: '8px' }}
                                />
                                <Text
                                    appearance="primary"
                                    style={{
                                        color: '#5EC090',
                                        fontSize: '13px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Add Type
                                </Text>
                            </Pane>
                        </>
                    )}
                </Pane>
            ))}
            <Button
                iconBefore={<PlusIcon size={11} />}
                appearance="primary"
                minWidth={125}
                marginTop={16}
                isLoading={categoryConfirmLoading}
                type="button"
                onClick={() => {
                    setSelectedCategory(null);
                    setSelectedType(null);
                    setEditingModal(false);
                    setDeleteModal(false);
                    handleOpenDialog();
                    setModalType('category');
                    setForm((state) => ({
                        ...state,
                        values: {
                            ...state.values,
                            category: {
                                name: '',
                                abbreviation: '',
                            },
                            type: {
                                name: '',
                                categoryId: '',
                            },
                        },
                        errors: {
                            ...state.errors,
                            category: {
                                name: '',
                                abbreviation: '',
                            },
                            type: {
                                name: '',
                            },
                        },
                    }));
                }}
            >
                Add Category
            </Button>
        </Pane>
    );
}

MaintenanceTemplateForm.propTypes = {
    handleOpenDialog: PropTypes.string.isRequired,
    setModalType: PropTypes.string.isRequired,
    setEditingModal: PropTypes.string.isRequired,
    setDeleteModal: PropTypes.string.isRequired,
    setModalData: PropTypes.string.isRequired,
    categories: PropTypes.string.isRequired,
    handleCategoryChange: PropTypes.string.isRequired,
    handleTypeChange: PropTypes.string.isRequired,
    selectedCategory: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        abbreviation: PropTypes.string,
    }),
    selectedType: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        abbreviation: PropTypes.string,
    }),
    categoryConfirmLoading: PropTypes.bool,
    setSelectedCategory: PropTypes.string.isRequired,
    setSelectedType: PropTypes.string.isRequired,
    setForm: PropTypes.string.isRequired,
    setSelectedCategories: PropTypes.string.isRequired,
};

MaintenanceTemplateForm.defaultProps = {
    selectedCategory: null,
    selectedType: null,
    categoryConfirmLoading: null,
};

const Item = styled(Text)`
    padding: 10px 20px 10px 20px;
    width: 100%;
    cursor: pointer;
    color: ${({ isExpanded }) =>
        isExpanded ? colors.secondary500 : '#696f8c'};
    border-bottom: 1px solid
        ${({ isExpanded }) =>
            isExpanded ? colors.secondary500 : 'transparent'};
`;

const ItemPane = styled(Pane)`
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    cursor: pointer;
    color: ${({ isExpanded }) =>
        isExpanded ? colors.secondary500 : '#696f8c'};
    border-bottom: 1px solid
        ${({ isExpanded }) =>
            isExpanded ? colors.secondary500 : 'transparent'};
    padding-bottom: ${({ isExpanded }) => (isExpanded ? '10px' : '0')};
    padding-top: ${({ isExpanded }) => (isExpanded ? '10px' : '0')};
`;

export default MaintenanceTemplateForm;
